<template>
  <section class="section is-medium has-background-yellow-light">
    <div class="container">
      <div class="columns">
        <div class="column is-2">
          <page-header-logo class="footer-logo" />
        </div>
        <div class="column is-8">
          <h2 class="title is-2">{{ title }}</h2>
          <div v-if="text" class="content is-size-6" v-html="text" />
          <div class="columns">
            <div class="column is-8">
              <div
                v-if="benefits"
                class="is-size-6 content"
                v-html="benefits"
              />
              <div class="buttons">
                <VuepalLink
                  v-if="ctaLink"
                  :to="ctaLink?.uri?.path"
                  class="button is-link mr-4"
                  >{{ ctaLink.title }}
                </VuepalLink>
                <VuepalLink
                  v-if="moreLink"
                  :to="moreLink?.uri?.path"
                  class="button is-link is-inverted"
                  >{{ moreLink.title }}
                </VuepalLink>
              </div>
            </div>
            <div class="column">
              <div class="image-wrapper">
                <MediaImage
                  v-if="media"
                  v-bind="media"
                  :image-style="frontpageHero"
                  class="image rounded membership-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ParagraphMembershipTeaserFragment } from '#graphql-operations'

const frontpageHero = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    xs: 445,
  },
})

defineProps<{
  title?: string
  text?: string
  benefits?: string
  ctaLink?: ParagraphMembershipTeaserFragment['ctaLink']
  moreLink?: ParagraphMembershipTeaserFragment['moreLink']
  media?: ParagraphMembershipTeaserFragment['media']
}>()

defineBlokkli({
  bundle: 'membership_teaser',
})
</script>

<style lang="scss">
.membership-image {
  max-width: 300px;
  @include tablet {
    max-width: 100%;
  }
}
</style>
