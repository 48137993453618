<template>
  <section class="section is-medium" :class="view">
    <div class="container">
      <h2 v-if="title" class="title is-2">{{ title }}</h2>
      <ViewsArticleTopTeaserList
        v-if="view && view === 'article_top_teaser_list'"
      />
      <ViewsArticleTopList v-if="view && view === 'article_top_list'" />
      <ViewsArticleListFront v-if="view && view === 'article_list_front'" />
      <div v-if="link" class="buttons is-centered">
        <VuepalLink :to="link?.uri?.path" class="button is-primary"
          >{{ link.title }}
        </VuepalLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ParagraphViewsFragment } from '#graphql-operations'

defineProps<{
  title?: string
  view?: string
  link?: ParagraphViewsFragment['link']
}>()

defineBlokkli({
  bundle: 'views',
})
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 3rem;
}
</style>
