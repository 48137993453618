<template>
  <section class="section subsequent">
    <div class="container narrow">
      <h2 v-if="title" v-blokkli-editable:field_title class="title is-2">
        {{ title }}
      </h2>
      <div class="accordion-list">
        <!--        <ParagraphAccordionElement-->
        <!--          v-for="element in elements"-->
        <!--          v-bind="element"-->
        <!--          :key="element.id"-->
        <!--        />-->
        <BlokkliField :list="elements" name="field_elements" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ParagraphAccordionFragment } from '#graphql-operations'

defineProps<{
  title?: string
  elements: ParagraphAccordionFragment['elements']
}>()

defineBlokkli({
  bundle: 'accordion',
})
</script>
