<template>
  <div
    :id="anchorId"
    class="accordion is-stretched-mobile"
    :class="{ 'is-active': open || isEditing }"
  >
    <h3>
      <a
        :id="anchorId + '-button'"
        :href="anchor"
        class="accordion-toggle title is-5"
        :class="{ 'is-active': open || isEditing }"
        :aria-expanded="open"
        :aria-controls="anchorId + '-content'"
        @click.prevent="toggle"
      >
        <span class="accordion-icon">
          <SpriteSymbol name="chevron-right" />
        </span>
        <slot name="header">
          <span v-blokkli-editable:field_title>{{ title }} </span>
        </slot>
      </a>
    </h3>
    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div
        v-show="open || isEditing"
        :id="anchorId + '-content'"
        class="accordion-body-wrapper"
        :aria-labelledby="anchorId + '-button'"
        role="region"
        :aria-hidden="!open"
      >
        <div ref="body" class="accordion-body">
          <div class="accordion-content">
            <slot>
              <div
                v-blokkli-editable:field_text="{ type: 'frame' }"
                class="content"
                v-html="text"
              />
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { slugify } from '#vuepal/helpers/url'

const props = defineProps<{
  title?: string
  text?: string
}>()

const open = ref(false)

const anchorId = computed(() => slugify(props.title ?? ''))
const anchor = computed(() => '#' + anchorId.value)

const toggle = () => {
  open.value = !open.value
}

const beforeEnter = (el: any) => {
  el.style.height = '0'
}

const enter = (el: any) => {
  el.style.height = el.scrollHeight + 'px'
}

const beforeLeave = (el: any) => {
  el.style.height = el.scrollHeight + 'px'
}

const leave = (el: any) => {
  el.style.height = '0'
}

const { isEditing } = defineBlokkli({
  bundle: 'accordion_element',
})
</script>
