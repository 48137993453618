<template>
  <div class="top-list">
    <NodeArticleTeaser
      v-for="(teaser, index) in articles?.rows"
      :key="teaser.nid"
      :index="index === 0 ? 'first' : ''"
      v-bind="teaser"
    />
  </div>
</template>

<script lang="ts" setup>
import type { NodeArticleTeaserFragment } from '#graphql-operations'

const props = defineProps<{
  tids?: [number]
}>()

const { data: articles } = await useAsyncData(async () => {
  const variables = {
    tids: 'all',
  }

  if (props.tids?.length) {
    variables.tids = props.tids.join('+')
  }
  return await useGraphqlQuery('articleTopList', variables).then((v) => {
    return {
      rows:
        // @ts-ignore
        (v.data?.entityById?.executable?.execute
          ?.rows as NodeArticleTeaserFragment[]) || [],
    }
  })
})
</script>

<style lang="scss">
.top-list {
  @include mobile {
    margin: 0 -1rem;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    scroll-snap-type: x mandatory;
    .teaser {
      scroll-snap-align: start;
      padding-left: 1rem;
      flex: 0 0 16rem;
      &:last-child {
        padding-right: 1rem;
      }
    }
  }
  @include tablet {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: var(--gap-v) var(--gap-h);
  }
  @include desktop {
    .teaser:first-child {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
  }
}
</style>
