<template>
  <section class="section subsequent">
    <div class="container narrow">
      <div class="quote-wrapper">
        <div v-if="quote" class="quote">„{{ quote.trim() }}“</div>
        <div class="is-flex is-flex-direction-row is-align-items-center mt-5">
          <div v-if="media" class="image-wrapper">
            <MediaImage
              v-if="media"
              :hide-caption="true"
              v-bind="media"
              :image-style="quoteStyle"
              class="image rounded"
            />
          </div>
          <div class="ml-5">
            {{ title }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ParagraphMembershipTeaserFragment } from '#graphql-operations'

const quoteStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    xs: 65,
  },
})

defineProps<{
  title?: string
  quote?: string
  media?: ParagraphMembershipTeaserFragment['media']
}>()

defineBlokkli({
  bundle: 'quote',
})
</script>

<style lang="scss" scoped>
.quote-wrapper {
  border: 2px solid #a4def5;
  border-radius: 20px;
  padding: 1rem;
  @include tablet {
    padding: 1.5rem 2rem;
  }
  @include widescreen {
    padding: 3rem 7rem;
  }
}
.quote {
  font-size: $size-5;
  font-weight: $weight-extrabold;
  color: $grey;
}

.image-wrapper {
  max-width: 65px;
}
</style>
