<template>
  <div v-if="articles" class="teaser-list-four">
    <NodeArticleTeaser
      v-for="teaser in articles?.rows"
      :key="teaser.nid"
      v-bind="teaser"
    />
  </div>
</template>

<script lang="ts" setup>
import type { NodeArticleTeaserFragment } from '#graphql-operations'

const props = defineProps<{
  tids?: [number]
}>()

const { data: articles } = await useAsyncData(async () => {
  const variables = {
    tids: 'all',
    limit: 4,
  }

  if (props.tids?.length) {
    variables.tids = props.tids.join('+')
  }

  return await useGraphqlQuery('articleTopList', variables).then((v) => {
    return {
      rows:
        // @ts-ignore
        (v.data?.entityById?.executable?.execute
          ?.rows as NodeArticleTeaserFragment[]) || [],
    }
  })
})
</script>

<style lang="scss">
.article_top_teaser_list {
  background-color: $yellow_light;
}
</style>
