import type { BlockOptionDefinition } from '#blokkli/types/blokkOptions'

type GlobalOptionsDefaults = {
  type: BlockOptionDefinition['type']
  default: any
}

export const globalOptionsDefaults: Record<string, GlobalOptionsDefaults> = {
  "teaserStyle": {
    "default": "grid",
    "type": "radios"
  },
  "mobileStyle": {
    "default": "stapel",
    "type": "radios"
  }
} as const