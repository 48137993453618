<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<Message
      v-for="(message, index) in messages"
      :key="index"
      :title="$texts('hint', 'Hinweis')"
      :type="message.type"
      aria-close-label="Schliessen"
      :show-title="message.showTitle"
      class="mb-6"
    >
      <p v-html="message.message" />
    </Message>
    <client-only>
      <FormKit
        id="newsletterForm"
        type="form"
        class="newsletter-form"
        :actions="false"
        @submit="onSubmit"
      >
        <div class="field is-grouped is-grouped-centered">
          <FormKit
            v-model="mail"
            validation="required|email"
            type="email"
            name="mail"
            :classes="{
              inner: 'mr-4',
            }"
            :placeholder="$texts('email', 'E-Mail')"
          />

          <FormKit
            type="submit"
            :disabled="state.isSending"
            class="button is-warning"
            :classes="{
              input: '$reset button is-warning',
            }"
          >
            {{ $texts('subscribe', 'Abonnieren') }}
          </FormKit>
        </div>
      </FormKit>
    </client-only>
</FormKitLazyProvider>

  </div>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
const mail = ref('')
const state = ref({
  isSending: false,
})

const { $texts } = useNuxtApp()
type Message = {
  type: string
  message: string
  showTitle: boolean
}

const messages = ref<Message[]>([])
const onSubmit = async () => {
  try {
    await useFetch('/api/newsletter', {
      method: 'POST',
      body: {
        email: mail.value,
      },
    })

    messages.value.push({
      type: 'is-warning',
      showTitle: false,
      message: 'Erfolgreich angemeldet!',
    })
  } catch (error: any) {
    messages.value.push({
      type: 'is-error',
      showTitle: false,
      message: error.message,
    })
  }
}
</script>

<style lang="scss">
.newsletter-form {
  @include mobile {
    > .field {
      display: block;
      .control {
        margin-right: 0 !important;
      }
      .button {
        margin-top: 1rem;
      }
    }
  }
}
</style>
