<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-half">
          <h1 class="title is-h1 is-hero-title">{{ title }}</h1>
          <div v-if="text" class="is-size-6 mb-6" v-html="text" />
          <VuepalLink
            v-if="ctaLink"
            :to="ctaLink.uri?.path"
            class="button is-link mr-4"
            >{{ ctaLink.title }}
          </VuepalLink>
          <VuepalLink
            v-if="moreLink"
            :to="moreLink.uri?.path"
            class="button is-link is-inverted"
            >{{ moreLink.title }}
          </VuepalLink>
        </div>
        <div class="column is-offset-1 has-text-centered-mobile">
          <MediaImage
            v-if="media"
            :hide-caption="true"
            v-bind="media"
            :image-style="frontpageHero"
            class="image rounded frontpage-hero"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ParagraphFrontpageHeroFragment } from '#graphql-operations'

const frontpageHero = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1 / 1,
  sizes: {
    xs: 445,
  },
})

defineProps<{
  uuid: string
  title?: string
  text?: string
  ctaLink?: ParagraphFrontpageHeroFragment['ctaLink']
  moreLink?: ParagraphFrontpageHeroFragment['moreLink']
  media?: ParagraphFrontpageHeroFragment['media']
}>()

defineBlokkli({
  bundle: 'frontpage_hero',
})
</script>

<style lang="scss" scoped>
.frontpage-hero {
  max-width: 300px;
  @include tablet {
    max-width: 100%;
  }
}
</style>
