<template>
  <section class="section has-background-green is-medium">
    <div class="container">
      <div class="columns">
        <div class="column is-offset-2 is-8">
          <div class="has-text-centered">
            <div class="overline mb-4">
              {{ overline }}
            </div>
            <h2 class="title is-3 is-size-2-tablet mb-6">{{ title }}</h2>
            <FormsNewsletter />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
defineProps<{
  title?: string
  overline?: string
}>()

defineBlokkli({
  bundle: 'newsletter',
})
</script>

<style lang="scss">
@import '~/assets/scss/helper/mixins.scss';

.overline {
  margin-top: -1rem;
  font-size: rem(14);
  text-transform: uppercase;
  color: $white;
}

.has-background-green {
  background-color: $green;
  .title {
    color: $white;
  }
}
</style>
